"use strict";

import ng from "angular";
import { configuratorApp } from "@produktlogika/configurator-web/configuratorApp";
import { ServiceProvider } from "@produktlogika/configurator-web/ServiceProvider";
import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions } from "keycloak-js";

configuratorApp.config(["$logProvider", ($logProvider: ng.ILogProvider) => {
    $logProvider.debugEnabled(true);
}]);

declare const APP_CONFIG: {
    apiRoot: string,
    unDoBufferLength: number,
    stateStoreType: string,
    appBc: string
};

configuratorApp.constant("unDoBufferLength", APP_CONFIG.unDoBufferLength);
configuratorApp.constant("stateStoreType", APP_CONFIG.stateStoreType);
configuratorApp.constant("appBc", APP_CONFIG.appBc);

declare const emailElementId: string;
configuratorApp.constant("emailElementId", emailElementId);

declare const use3D: boolean;
configuratorApp.constant("use3D", use3D);

declare const useKeycloak: boolean;
declare const apiRoot: string;
declare const KEYCLOAK_CONFIG: KeycloakConfig;
if (useKeycloak) {
    configuratorApp.config(["pmServiceProvider", (pmServiceProvider: ServiceProvider) => {
        pmServiceProvider.setUrl(apiRoot);
    }]);

    const kc: Keycloak = new Keycloak(KEYCLOAK_CONFIG);
    configuratorApp.constant("keycloak", kc);

    configuratorApp.factory("pmHttpInterceptor", (): ng.IHttpInterceptor => {
        const httpInterceptor: ng.IHttpInterceptor = Object.create(null);
        httpInterceptor.request = (config: ng.IRequestConfig): ng.IRequestConfig => {
            config.headers.Authorization = "Bearer " + kc.token;
            return config;
        };
        return httpInterceptor;
    });
    configuratorApp.config(["$httpProvider", ($httpProvider: ng.IHttpProvider) => {
        $httpProvider.interceptors.push("pmHttpInterceptor");
    }]);

    kc.onTokenExpired = () => {
        console.log("token expired", kc.token);
        kc.updateToken(60)
            .then(() => {
                console.log("successfully got a new token", kc.token);
            })
            .catch((result) => {
                console.log("update token failed:", result);
            });
    }

    const initOptions: KeycloakInitOptions = {
        //onLoad: "login-required"
        //enableLogging: true
    };
    kc.init(initOptions)
        .then((authenticated) => {
            if (!authenticated) {
                const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
                const localeCode = urlSearchParams.get("localeCode");
                const loginOptions: KeycloakLoginOptions = {
                    locale: localeCode
                }
                kc.login(loginOptions);
            } else {
                console.log("authenticated: ", kc.idTokenParsed.name);
                kc.loadUserProfile()
                    .then((userProfile) => {
                        console.log("userProfile: ", userProfile);
                        ng.bootstrap(document, ["configuratorApp"], { strictDi: true });
                    })
                    .catch((result) => {
                        console.log("Keycloak load user profile failed:", result);
                    });
            }
        })
        .catch((result) => {
            console.log("Keycloak init failed:", result);
            kc.logout();
        });
} else {
    configuratorApp.config(["pmServiceProvider", (pmServiceProvider: ServiceProvider) => {
        pmServiceProvider.setUrl(APP_CONFIG.apiRoot);
    }]);
    configuratorApp.constant("keycloak", null);
    ng.bootstrap(document, ["configuratorApp"], { strictDi: true });
}
